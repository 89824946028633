import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    component: () => import('layouts/FullscreenLayout.vue'),
    children: [{ path: '', component: () => import('pages/LoginPage.vue') }],
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/IndexPage.vue') },
      {
        path: '/customers',
        component: () => import('components/CustomerList.vue'),
      },
      {
        path: '/customers/create',
        component: () => import('components/CustomerCard.vue'),
      },
      {
        path: '/licensePackages',
        component: () => import('components/LicenseTable.vue'),
      },
      {
        path: '/licensePackages/create',
        component: () => import('components/LicenseCard.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
